/* eslint-disable no-empty-pattern */
import service from "../service";
import { isEmpty } from "lodash";
import ajax from "@/customApi";
import config from "../../../../config";
import { orderBy, mapKeys, camelCase } from "lodash";
const qs = require("qs");
import { eventBus } from "@/main";

const { path, messages } = config;

const actions = {
  async LogIn({ commit }, User) {
    try {
      let data = await service.LogIn(User);

      if (isEmpty(data)) {
        return false;
      } else {
        let result = JSON.parse(data.result)[0];

        if ("json" in result && result.json != "") {
          return { status: 0, message: result.json };
        }

        let sites = result.Site;
        delete result.Site;

        const sortedSites = orderBy(
          sites,
          [(site) => site.Name.toLowerCase()],
          ["asc"]
        );

        let user = mapKeys(result, (v, k) => camelCase(k));

        commit("setAccessToken", data.accessToken);
        commit("setRefreshToken", data.refreshToken);
        commit("setSiteAccess", sortedSites);
        commit("setUser", user);

        return true;
      }
    } catch (error) {
      return false;
    }
  },
  async LogOut({ commit }) {
    commit("LogOut");
  },
  async updateProfile({ commit, getters }, payload) {
    let data = await service.updateProfile(payload);
    if (data.status == 1) {
      let user = Object.assign({}, getters["user"]);
      user.firstName = payload.firstName;
      user.lastName = payload.lastName;
      commit("setUser", user);
    }
    return data;
  },
  async updatePassword(_, payload) {
    let data = await service.updatePassword(payload);
    return data;
  },
  async getModuleMasterList() {
    try {
      const { data } = await ajax.get(path.getmodulemasterlist, {
        name: "getModuleMasterList",
      });

      return data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
      }
      return {};
    }
  },
  async getUserModulerPermission(context) {
    let user = context.getters["user"];
    try {
      const { data } = await ajax.get(path.getUsermodulerPermissionById, {
        name: "getUserModulerPermission",
        params: {
          userId: user.Id,
        },
      });
      return data;
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
      }
      return {};
    }
  },
  updateNotificationSeen({ commit }, params) {
    try {
      ajax.post(path.updateNotificationSeen, {
        name: "updateNotificationSeen",
        body: null,
        params,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "comma" });
        },
      });

      commit("updateNotificationSeen");
    } catch (error) {
      if (error.response.status === 401) {
        eventBus.$emit("apiError", messages.unauthorizedUser);
        return;
      } else eventBus.$emit("apiError", error.message);

      ajax.post(path.exception, {
        name: "updateNotificationSeen",
        body: {
          id: 0,
          userId: params.userId,
          exceptionMessage: error.response.data,
          controllerName: "VersionHistory.vue",
          actionName: "Update Notification Seen",
          // exceptionStackTrace: error.stack,
          exceptionStackTrace: `API : ${path.updateNotificationSeen}`,
          moduleName: "Version History",
          isApi: 0,
        },
      });
    }
  },
  async resetPassword({}, payload) {
    try {
      const data = await service.resetPassword(payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async verifyResetPasswordLinkExpiry({}, payload) {
    try {
      const data = await service.verifyResetPasswordLinkExpiry(payload);
      return data;
    } catch (error) {
      return error;
    }
  },
  async changePassword({}, payload) {
    try {
      const data = await service.changePassword(payload);
      return data;
    } catch (error) {
      return error;
    }
  },
};

export default actions;

import axios from "axios";
import config from "../../../config";
import ajax from "@/customApi";
import { eventBus } from "@/main";
const qs = require("qs");

const { path, messages } = config;

const service = {
  LogIn,
  updateProfile,
  updatePassword,
  resetPassword,
  verifyResetPasswordLinkExpiry,
  changePassword,
};

async function LogIn(user) {
  try {
    const { data } = await axios.post(path.login, user);

    return data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

async function updateProfile(params) {
  try {
    const { data } = await ajax.post(path.manageUsers, {
      name: "updateProfile",
      body: params,
    });

    if (data["0"] == "Update") {
      return { status: 1, data: null };
    } else {
      return { status: 0, data: null, error: messages.somethingwentWrong };
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return { status: 0, data: null, error: error };
  }
}

async function updatePassword(params) {
  try {
    const { data } = await ajax.post(path.changePassword, {
      name: "updatePassword",
      body: null,
      params,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });

    if (data == 1) {
      return { status: 1, data: null };
    } else if (data == 2) {
      return { status: 2, data: null, error: messages.incorrectOldPassword };
    } else if (data == 3) {
      return { status: 0, data: null, error: messages.samePassword };
    } else if (data == 4) {
      return { status: 0, data: null, error: messages.securityRequirment };
    } else {
      return { status: 0, data: null, error: messages.somethingwentWrong };
    }
  } catch (error) {
    if (error.response.status === 401) {
      eventBus.$emit("apiError", messages.unauthorizedUser);
    }
    return { status: 0, data: null, error: error };
  }
}
async function resetPassword(params) {
  try {
    const response = await axios.post(path.resetPassword, params);
    return { status: response.status, data: response.data, error: null };
  } catch (error) {
    return {
      status: error.response.status,
      message: error?.response?.data?.message,
    };
  }
}

async function verifyResetPasswordLinkExpiry(params) {
  try {
    const queryParams = new URLSearchParams(params);
    const response = await axios.get(
      path.verifyResetPasswordLinkExpiry + "?" + queryParams.toString()
    );
    return { status: response.status };
  } catch (error) {
    return {
      status: error.response.status,
      message: error?.response?.data?.message,
    };
  }
}
async function changePassword(params) {
  try {
    const response = await axios.post(path.confirmPassword, params);
    return { status: response.status };
  } catch (error) {
    return {
      status: error.response.status,
      message: error?.response?.data?.message,
    };
  }
}
export default service;
